// Fonts are added in the css file.
// import 'typeface-roboto';
import './src/css/font.css'
import './src/css/style.css'

export const onRouteUpdate = () => {
  if (typeof window !== 'undefined') {
    // Remove sliding menu open state class if available
    if (document.body.classList.contains('menu-is-open')) {
      window.__toggleSlidingMenu()
    }

    window.customLocation = window.customLocation || []
    const length = window.customLocation.length

    // Add only for empty array.
    //   This is to avoid adding the same route twice on full page reload.
    let path = length === 0 ? document.referrer : window.location.href
    window.customLocation.push(path.replace(/^.*\/\/[^\/]+/, ''))
    window.previousPath = window.customLocation[length - 1]
    if (length > 2) window.customLocation.shift()
  }
}
